<template>
  <div
    class="flex relative flex-col justify-between bg-white shadow-md p-8 rounded-2xl group hover:shadow-xl"
  >
    <div class="w-full flex flex-col justify-stretch items-start">
      <span
        v-if="data.heading"
        class="mb-2 inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium text-ces-teal ring-1 ring-inset ring-ces-teal"
        >{{ data.heading }}</span
      >
      <a v-if="data.href" :href="data.href" target="_blank" rel="noopener noreferrer">
        <span class="absolute inset-0" />
        <h2 class="text-xl font-semibold group-hover:text-ces-blue text-wrap">
          {{ data.title }}
        </h2>
      </a>
      <span v-if="data.date" class="text-xs">{{ data.date }}</span>
    </div>
    <span
      class="mt-6 text-base font-Inter leading-6 justify-self-end group-hover:text-ces-yellow underline underline-offset-4"
    >
      Read more >
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',

  props: {
    data: { type: Object, required: true },
  },
})
</script>
