<template>
  <!-- Static sidebar for desktop -->
  <div class="hidden lg:block lg:w-[18rem] 3xl:w-96">
    <div
      class=" lg:justify-between lg:h-full lg:z-50 lg:flex lg:w-96 lg:flex-col shadow-lg"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto p-6">
        <div class="flex py-4 shrink-0 items-center w-full space-x-4 relative">
          <img
            v-if="selectedSchool?.logo"
            class="h-16 w-16 rounded-full border-lightgray"
            :src="selectedSchool.logo"
            alt="School logo"
          />
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-16 h-16"
          >
            <path
              fill-rule="evenodd"
              d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
              clip-rule="evenodd"
            />
          </svg>
          <div class="flex flex-col w-full">
            <h2 v-if="selectedSchoolName" class="text-lg leading-5 mb-0">
              {{ selectedSchoolName }}
            </h2>
            <div
              v-if="canSelectSchool"
              class="w-full flex justify-between text-ces-teal hover:text-ces-yellow cursor-pointer mt-2"
              @click="toggleSchoolSelection"
            >
              <span class="text-sm leading-4">Switch school</span>
              <svg-icon
                size="16"
                icon-name="arrow"
                aria-hidden="true"
                :class="{ 'transition rotate-180': isSelectingSchool }"
              />
            </div>
            <div
              v-if="isSelectingSchool"
              class="mx-0 absolute right-0 top-20 bg-white border rounded-xs border-lightgray shadow-lg w-full"
            >
              <ul ref="schoolsListRef" class="max-h-44 w-full overflow-y-scroll">
                <li
                  v-for="item in schools"
                  :key="item.uuid"
                  class="border-b border-lightgray group py-1 px-4 cursor-pointer flex flex-row gap-1 items-center"
                  :class="{
                    'border-truegray-300' : item.last_user_school,
                    'bg-lightblue' : isSelectedSchool(item.id)
                  }"
                >
                  <a
                    class="block w-full text-xs font-normal leading-5 group-hover:text-ces-blue"
                    :class="isSelectedSchool(item.id) ? 'text-ces-blue' : 'text-gray-400'"
                    @click="() => selectSchool(item.id)"
                  >
                    {{ item.name }}
                  </a>
                  <svg v-if="item.is_user_school" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-ces-blue">
                    <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
                  </svg>
                </li>
              </ul>
              <div
                v-if="schools.length > 6"
                class="flex p-2 justify-center cursor-pointer"
                @click="scrollSchoolsList"
              >
                <svg-icon
                  aria-hidden="true"
                  size="16"
                  icon-name="arrow"
                  class="animate-bounce text-gray-400 hover:text-ces-teal"
                />
              </div>
            </div>
          </div>
        </div>
  
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navRoutes" :key="item.name">
                  <router-link
                    :to="{ name: item.name, params: { id: route.params.id } }"
                    :class="[
                      isCurrentRoute(item)
                        ? 'bg-lightblue hover:text-ces-blue cursor-auto'
                        : 'hover:text-ces-teal',
                      'group flex items-center gap-x-4 rounded-md px-6 py-4 text-lg leading-6 font-semibold',
                    ]"
                  >
                    <svg-icon
                      size="16"
                      :icon-name="item.meta?.navConfig?.icon"
                      aria-hidden="true"
                      :class="[
                        isCurrentRoute(item) ? '' : 'group-hover:text-ces-teal',
                        'shrink-0',
                      ]"
                    />
                    {{ item.meta?.navConfig?.name }}
                  </router-link>
                </li>
  
                <ModalVideo />
              </ul>
            </li>
            <li class="mt-auto">
              <div
                :class="
                  servicesOpen
                    ? 'h-32 pb-4 top-0 opacity-100'
                    : 'h-0 pb-0 overflow-hidden pointer-events-none top-2 opacity-0'
                "
                class="flex flex-col space-y-2 pl-4 transition-all relative z-10"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cessl.org.nz/services/payroll/"
                  class="text-xs font-light text-ces-blue hover:text-ces-teal"
                  >Payroll</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cessl.org.nz/services/school-elections/"
                  class="text-xs font-light text-ces-blue hover:text-ces-teal"
                  >School Elections</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cessl.org.nz/services/board-services/"
                  class="text-xs font-light text-ces-blue hover:text-ces-teal"
                  >Board Services</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cessl.org.nz/services/financial-service/"
                  class="text-xs font-light text-ces-blue hover:text-ces-teal"
                  >Financial Services</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cessl.org.nz/services/asset-management/"
                  class="text-xs font-light text-ces-blue hover:text-ces-teal"
                  >Asset Management</a
                >
              </div>
              <span
                class="hover:text-ces-teal text-ces-blue text-base font-semibold flex items-center cursor-pointer z-20 bg-white pl-1"
                @click="toggleServices"
              >
                <svg
                  :class="servicesOpen ? '' : 'rotate-180'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 mr-4 transform transition-all"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>
                My Services
              </span>
            </li>
            <li
              class="-mx-6 border-b border-t px-6 py-6 flex justify-between items-center"
              :class="[
                isCurrentRoute({ name: KEY_SERVICES })
                  ? 'bg-lightblue hover:text-ces-blue cursor-auto'
                  : 'hover:text-ces-teal',
              ]"
            >
              <router-link
                :to="{ name: 'myBoard', params: { id: route.params.id } }"
                class="flex items-center gap-x-3 text-sm font-semibold group"
              >
                <div
                  class="h-8 w-8 rounded-full bg-ces-teal flex justify-center items-center shrink-0"
                >
                  <span class="font-bold text-white text-sm">
                    {{ selectedSchoolName?.slice(0, 2) }}
                  </span>
                </div>
                <span class="group-hover:text-ces-teal text-ces-blue text-base"
                  >My Board</span
                >
              </router-link>
            </li>
            <li class="flex justify-between items-center h-px pb-2">
              <div class="text-truegray-500 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 mr-2 flex-shrink-0">
                  <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm max-w-[12rem] truncate overflow-ellipsis pr-4">{{user.name}}</span>
              </div>
              <button
                v-if="!isSuperAdmin"
                title="Sign Out"
                type="button"
                class="flex justify-center"
                @click="signOut"
              >
                <svg-icon
                  size="24"
                  icon-name="signout"
                  aria-hidden="true"
                  class="text-gray-300 hover:text-ces-blue"
                />
                <span class="sr-only">Sign Out</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import ModalVideo from '../components/modal/ModalVideo.vue'

export default {
  components: {
    ModalVideo
  },

  setup() {
    const store = useStore()
    const isSuperAdmin = store.getters['auth/isSuperAdmin']
    const user = store.getters['auth/getUser']

    const router = useRouter()
    const route = useRoute()
    const navRoutes = router
      .getRoutes()
      .filter((route) => route.meta?.navConfig)
      .sort(
        (a, b) =>
          a.meta?.navConfig?.displayOrder - b.meta?.navConfig?.displayOrder,
      )
    const KEY_SERVICES = 'services'

    const isCurrentRoute = (item) => {
      if (item.name === KEY_SERVICES) {
        const matchedRoutes = router
          .getRoutes()
          .filter((route) => route.meta?.services)
          .map((route) => route.name)
        return matchedRoutes.includes(route.name)
      }

      return route.name === item.name
    }

    const schoolsListRef = ref(null)
    const isSelectingSchool = ref(false)

    const schools = computed(() => {
      const schools = store.getters['portal/getSchools']
      const schoolsSorted = schools.sort((a, b) => {
        if (a.is_user_school === b.is_user_school) {
          // If both have the same is_user_school value, sort alphabetically
          return a.name.localeCompare(b.name)
        } else if (a.is_user_school) {
          // If a.is_user_school is true, a should come first
          return -1
        } else {
          // If b.is_user_school is true, b should come first
          return 1
        }
      })

      const lastUserSchool = schoolsSorted.findLast((school) => !!school.is_user_school) || null
      if (lastUserSchool) {
        lastUserSchool.last_user_school = true
      }

      return schoolsSorted
    })

    const selectedSchool = computed(() => {
      return store.getters['portal/getSchool'] || null
    })

    const selectedSchoolId = computed(() => {
      return store.getters['portal/getClientId']
    })

    const selectedSchoolName = computed(() => {
      return selectedSchool.value?.name
    })

    const canSelectSchool = computed(() => {
      return schools.value.length > 1
    })

    const isSelectedSchool = (id) => {
      return id === selectedSchoolId.value
    }

    const scrollSchoolsList = () => {
      const scrollOptions = {
        top: schoolsListRef.value.scrollTop + 150,
        behavior: 'smooth',
      }
      schoolsListRef.value.scrollTo(scrollOptions)
    }

    const toggleSchoolSelection = () => {
      isSelectingSchool.value = !isSelectingSchool.value
    }

    const selectSchool = async (id) => {
      if (id !== selectedSchoolId.value) {
        await store.dispatch('portal/dispatchSetSchool', id)

        // reroute to update params and refresh school data
        router.push({ name: route.name, params: { id } })
      }

      toggleSchoolSelection()
    }

    const signOut = async () => {
      await store.dispatch('portal/dispatchSignOut')
      router.push({ path: '/' })
    }

    const servicesOpen = ref(false)

    const toggleServices = () => {
      servicesOpen.value = !servicesOpen.value
    }

    return {
      isSuperAdmin,
      user,
      route,
      navRoutes,
      KEY_SERVICES,
      isCurrentRoute,
      schools,
      schoolsListRef,
      selectedSchool,
      selectedSchoolId,
      selectedSchoolName,
      isSelectedSchool,
      canSelectSchool,
      isSelectingSchool,
      scrollSchoolsList,
      toggleSchoolSelection,
      selectSchool,
      signOut,
      servicesOpen,
      toggleServices,
    }
  },
}
</script>
