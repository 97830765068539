<template>
  <div class="w-full flex justify-between items-center lg:h-[5vh] max-w-7xl py-4 px-6 mx-auto">
    <span class="text-white text-xs">
      © {{ new Date().getFullYear() }} CES App. Designed in partnership with
      <a
        :href="LINKS.DJ"
        target="_blank"
        rel="noopener noreferrer"
        class="underline hover:text-ces-teal text-xs"
        >Digital Journey</a
      >
    </span>
    <ModalTerms />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ModalTerms from '../components/modal/ModalTerms.vue'
import { LINKS } from '../constants.js'

export default defineComponent({
  name: 'PageFooter',

  components: {
    ModalTerms,
  },

  setup() {
    return {
      LINKS,
    }
  }
})
</script>
