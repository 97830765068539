export function UPDATE_SCHOOL(state, data) {
  state.school = data
}

export function CLEAR_SCHOOL(state) {
  state.school = null
}

export function UPDATE_SCHOOLS(state, data) {
  state.schools = data
}

export function CLEAR_SCHOOLS(state) {
  state.schools = []
}

export function UPDATE_MEETINGS(state, data) {
  state.meetings = data
}

export function CLEAR_MEETINGS(state) {
  state.meetings = []
}

export function UPDATE_REPORTS(state, data) {
  state.reports = data
}

export function CLEAR_REPORTS(state) {
  state.reports = []
}

export function UPDATE_OWNERS(state, data) {
  state.owners = data
}

export function CLEAR_OWNERS(state) {
  state.owners = []
}

export function UPDATE_MEMBERS(state, data) {
  state.members = data
}

export function CLEAR_MEMBERS(state) {
  state.members = []
}
