import { mapUser, mapReport } from '../util.js'

export function getSchool(state) {
  return state.school
}

export function getSchools(state) {
  return state.schools
}

export function getClientId(state) {
  return state.school?.id
}

export function getMeetings(state) {
  return state.meetings
}

export function getReports(state) {
  return state.reports
}

export function getReportsMapped(state) {
  return state.reports?.map(mapReport)
}

export function getReportsByYears(_state, getters) {
  const reports = getters.getReportsMapped
  const years = reports?.reduce(
    (acc, report) => {
      const year = new Date(report.created_at).getFullYear()
      if (!acc[year]) {
        acc[year] = []
      }
      acc[year].push(report)
      return acc
    },
    {
      ALL: reports,
    },
  )

  return years
}

export function getReportsDefaultYear(state, getters) {
  const ALL_KEY = 'ALL'
  const years = Object.keys(getters.getReportsByYears)
    .filter(val => val !== ALL_KEY)

  const defaultYear = years.length ? Math.max(...years) : ALL_KEY

  return String(defaultYear)
}

export function getOwners(state) {
  return state.owners
}

export function getOwnersMapped(_state, getters) {
  return getters.getOwners?.map(mapUser)
}

export function getMembers(state) {
  return state.members
}

export function getMembersMapped(_state, getters) {
  return getters.getMembers?.map(mapUser)
}

export const getMember = (_state, getters) => (id) => {
  return getters.getMembersMapped.find((member) => member.id === id) || null
}
