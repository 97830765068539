<template>
  <Modal :trigger="MODAL_CONFIG.trigger" :content-classes="MODAL_CONFIG.contentClasses">
    <template #content>
      <h3 class="text-h3 mb-2">
        Terms & Conditions of Use
      </h3>
      <p>
        Canterbury Education Services (CES) provides customers of CES with access to this Portal (Service).     
        By using our Portal you agree to be bound by the terms and conditions appearing in this document and as posted on the Portal. It is important that you are familiar with all of the terms applying to your use of our Portal. CES reserves the right to amend these Terms and Conditions of Use at any time without notice to you. You acknowledge and agree that it is your responsibility to check these Terms and Conditions of Use from time to time and familiarise yourself with them. Any new features that augment or enhance the existing Services, including the release of new tools or resources, shall be subject to these Terms and Conditions of Use.
      </p>
      <h3 class="text-h3 mb-2">
        Access and Use
      </h3>
      <p>
        CES grants you a limited, exclusive licence to access and use the Services in connection with your agreement with CES. This service is only available to CES customers and CES has the right to grant and deny access.
      </p>
      <h3 class="text-h3 mb-2">
        Your Account
      </h3>
      <p>
        As a user of the Portal, you are responsible for maintaining the security of your CES portal account, including keeping your password secure (if you have set one). We recommend the use of password management software to keep your password safe.
        CES will not be liable for any loss or damage from failure to comply with this security obligation.
        You may not use your account for any illegal or unauthorised purpose. You must not, in the use of the Services, violate any laws in your jurisdiction (including, but not limited to copyright laws). 
        CES does not pre-screen any Content (Board Reports, Account Reports) but reserves the right (but not the obligation) to refuse or remove any Content that is deemed inappropriate for storage in the Portal.
      </p>
      <p>
        Note: <span class="italic">Digital Journey has developed the Portal software and will maintain and provide technical assistance to deliver the Portal Service. Digital Journey will only access and modify the Portal software, data, access control and user permissions under the direction of CES. Digial Journey is not liable for any data loss, service availability or delivery of the service.</span>
      </p>
      <h3 class="text-h3 mb-2">
        Deactivation
      </h3>
      <p>
        CES may deactivate individual access or school access to the Portal. If access is no longer required CES will remove content and associated accounts.
      </p>
      <h3 class="text-h3 mb-2">
        Service Availability and Support
      </h3>
      <p>
        CES and its delivery partner, Digital Journey, aims to provide a high level of Service Availability. However, in the event of an outage or other technical infrastructure issue, CES and Digital Journey will not be liable for any losses, including, without limitation, data loss. Digital Journey will endeavour to restore availability in a timely manner. While Digital Journey will make all efforts to prevent any disruption to Service Availability during scheduled upgrades, there may be times where Products and/or Services are inaccessible.
      </p>
      <p class="mb-0">
        If you have any questions on the Portal please contact the Digital Journey Help Desk (<a :href="`mailto:${LINKS.HELP}`" class="text-ces-teal">{{ LINKS.HELP }}</a>), and for clarification on the Terms and Conditions contact CES.
      </p>
    </template>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import Modal from './Modal.vue'
import { LINKS } from '../../constants.js'

export default defineComponent({
  name: 'ModalTerms',

  components: {
    Modal,
  },

  setup() {
    const MODAL_CONFIG = {
      trigger: {
        text: 'Terms',
        as: 'a',
        classes: 'text-white hover:text-ces-teal',
      },

      contentClasses: 'max-h-[85vh] overflow-y-auto'
    }

    return {
      LINKS,
      MODAL_CONFIG,
    }
  },
})
</script>
