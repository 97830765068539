<template>
  <div class="w-full lg:flex p-12 lg:p-0 bg-white text-black">
    <div
      class="w-full lg:w-1/2 lg:min-h-screen flex space-y-4 flex-col justify-center items-center lg:p-12 mt-12 lg:mt-0"
    >
      <div class="w-full">
        <!-- Show the enquiry form -->

        <h5 class="font-black w-full text-2xl">Reset Your Password</h5>

        <div class="w-full z-10 relative">
          <div class="sm:grid sm:grid-cols-1 sm:gap-1 sm:items-start">
            <label
              for="password"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              New Password
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                class="block max-w-lg w-full shadow-sm focus:ring-teal-400 focus:border-teal-400 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div v-if="validationHint" class="py-2">
            <p class="text-red-600 text-xs">
              {{ validationHint }}
            </p>
          </div>

          <div class="flex space-x-2">
            <button
              :class="[
                canSubmit
                  ? 'hover:bg-lime-500'
                  : 'opacity-50 cursor-not-allowed',
                isSubmitting ? 'py-0' : 'py-2',
              ]"
              type="button"
              class="mt-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 bg-lime-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-400 sm:w-auto sm:text-sm"
              @click="submit"
            >
              <div v-if="isSubmitting" class="h-full w-8">
                <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
              </div>
              <span v-else> Reset password </span>
            </button>
            <button
              v-if="canSignIn"
              type="button"
              class="mt-2 inline-flex justify-center align-center rounded-md border border-lime-600 py-2 bg-white text-lime-600 shadow-sm px-4 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-400 sm:w-auto sm:text-sm hover:bg-lime-600 hover:text-white"
              @click="signIn"
            >
              Sign in
            </button>
          </div>
        </div>

        <!-- Show the enquiry form -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING, GENERIC_ERROR_MESSAGE } from '../../constants.js'

export default {
  components: {
    Vue3Lottie,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // Form
    const password = ref(null)
    const validationHint = ref(null)
    const passwordValid = computed(() => {
      // not empty!
      return !!password.value
    })

    const token = computed(() => route.query?.token)

    const isSubmitting = ref(false)
    const canSubmit = computed(() => {
      return passwordValid.value
    })
    const canSignIn = ref(false)

    const signIn = () => {
      router.push({ path: '/signin' })
    }

    const submit = async () => {
      if (!canSubmit.value) {
        return false
      }

      isSubmitting.value = true
      validationHint.value = null

      const response = await store.dispatch(
        'auth/dispatchValidateResetPassword',
        {
          password: password.value,
          token: token.value,
        },
      )

      isSubmitting.value = false

      if (response) {
        if (response.success) {
          router.push({
            path: '/signin',
            query: { email: response.data?.user?.email || null },
          })
        } else {
          canSignIn.value = true
          validationHint.value =
            response.alert?.message || GENERIC_ERROR_MESSAGE
        }
      } else {
        canSignIn.value = true
        validationHint.value = GENERIC_ERROR_MESSAGE
      }
    }

    return {
      ANIMATION_LOADING,
      password,
      validationHint,
      canSignIn,
      canSubmit,
      isSubmitting,
      signIn,
      submit,
    }
  },
}
</script>
