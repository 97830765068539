<template>
  <main>
    <PageHeader title="Board Documents">
      <template #action>
        <div>
          <label for="reportYear" class="block text-xs font-normal"
            >View reports from</label
          >
          <select
            id="reportYear"
            :key="selectedYear"
            v-model="selectedYearOption"
            name="reportYear"
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-ces-teal sm:text-sm sm:leading-6"
            @change="updateSelectedYear"
          >
            <option v-for="(value, key) in reportsByYears" :key="key">
              {{ getYearOptionValue(key) }}
            </option>
          </select>
        </div>
        <!-- <SelectAndSearch label="View report from"></SelectAndSearch> -->
      </template>
    </PageHeader>

    <PageContent wrapper-classes="pt-8">
      <template #default>
        <ModalFileUpload
          :file-model="activeUpdateFile"
          :edit="!!activeUpdateFile"
          :is-visible="isModalVisibleUpdateFile"
          @update:is-visible="updateIsModalVisibleUpdateFile"
          @modal-closed="resetModalUpdateFile"
        />
        <div class="flex flex-col">
          <div v-if="isAdmin" class="flex justify-start mb-7 ml-3">
            <button
              type="button"
              class="flex justify-center items-center rounded-full shadow-sm gap-x-2 px-4 py-2 text-base font-Inter font-bold bg-ces-blue text-white sm:w-auto sm:text-sm border border-transparent hover:text-ces-blue hover:bg-white hover:border-ces-blue"
              @click="() => showModalUpdateFile(null)">
              <svg-icon aria-hidden="true" size="16" icon-name="plus" />Add File
            </button>
          </div>
          <div class="flex flex-row ml-3">
            <TabsRoot
              class="flex flex-col mb-4"
              :model-value="selectedMonth"
              @update:modelValue="updateSelectedMonth"
            >
              <TabsList class="shrink-0 flex space-x-1">
                <TabsTrigger
                  v-for="(month, index) in selectedYearMonths"
                  :key="index"
                  class="bg-white px-3 h-[40px] flex items-center justify-center font-bold leading-none select-none data-[state=active]:text-ces-blue data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-current data-[state=active]:focus:relative outline-none cursor-pointer"
                  :value="month"
                >
                  {{ getMonthOptionValue(month) }}
                </TabsTrigger>
              </TabsList>
            </TabsRoot>
          </div>
          <table class="min-w-full divide-y divide-lightgray">
            <thead class="bg-lightgrey font-semibold">
              <tr>
                <th
                  scope="col"
                  class="hidden px-3 py-3.5 text-left text-xs lg:table-cell"
                >
                  Date
                </th>
                <th scope="col" class="px-6 py-3.5 lg:px-3 text-left text-xs">
                  Title
                </th>
                <th scope="col" class="px-6 py-3.5 lg:px-3 text-left text-xs">
                  Category
                </th>
                <th
                  scope="col"
                  class="hidden px-3 py-3.5 text-left text-xs lg:table-cell"
                >
                  Author
                </th>
                <th scope="col" class="relative py-3.5 p-4 text-xs text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-lightgray bg-white">
              <template v-if="!reportsByMonth?.length">
                <tr>
                  <td
                    class="w-full max-w-0 pt-8 pb-4 px-6 lg:pt-4 lg:px-3 sm:w-auto sm:max-w-none"
                  >
                    <p class="text-xs">No reports uploaded</p>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="report in reportsByMonth" :key="report.id">
                  <td
                    class="w-full max-w-0 pt-8 pb-4 px-6 lg:pt-4 lg:px-3 sm:w-auto sm:max-w-none"
                  >
                    <div class="flex items-center gap-x-2">
                      <svg-icon
                        size="16"
                        icon-name="calendar"
                        aria-hidden="true"
                      />
                      <span class="whitespace-nowrap text-xs">{{ report.date }}</span>
                      <div
                        class="lg:hidden flex items-center text-xs gap-x-2 ml-4 pl-2 border-l"
                      >
                        <svg-icon
                          size="16"
                          icon-name="user"
                          aria-hidden="true"
                        />
                        <span>{{ report.author }}</span>
                      </div>

                      <div
                        class="lg:hidden flex items-center text-xs gap-x-2 ml-4 pl-2 border-l"
                      >
                        <span>{{ report.category }}</span>
                      </div>
                    </div>

                    <dl class="lg:hidden">
                      <dt class="sr-only">Title</dt>
                      <dd class="mt-4 truncate text-gray-700">
                        {{ report.title }}
                      </dd>
                    </dl>
                  </td>
                  <td
                    class="hidden whitespace-normal break-words px-3 py-4 text-sm font-Inter lg:table-cell"
                  >
                    {{ report.title }}
                  </td>
                  <td
                    class="hidden whitespace-normal break-words px-3 py-4 text-sm font-Inter lg:table-cell"
                  >
                    {{ report.category }}
                  </td>
                  <td class="hidden px-3 py-4 lg:table-cell">
                    <div class="flex items-center gap-x-2">
                      <svg-icon size="16" icon-name="user" aria-hidden="true" />
                      <span class="whitespace-nowrap text-xs">
                        {{ report.author }}
                      </span>
                    </div>
                  </td>
                  <td class="flex items-end space-x-3 px-4 py-5">
                    <a
                      v-if="report.file"
                      :href="report.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="group hover:text-ces-teal text-xs relative"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 relative transition-all bottom-0 group-hover:bottom-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                      <span
                        class="w-full justify-center absolute top-5 font-normal hidden group-hover:flex"
                        >Download</span
                      >
                    </a>
                    <a
                      v-if="report.video_url"
                      :href="report.video_url"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="group hover:text-ces-teal text-xs relative"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 relative transition-all bottom-0 group-hover:bottom-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                      </svg>

                      <span
                        class="w-full justify-center absolute top-5 font-normal hidden group-hover:flex"
                        >View</span
                      >
                    </a>
                    <a
                      v-if="isAdmin"
                      class="group hover:text-ces-teal text-xs relative"
                      @click="() => showModalUpdateFile(report)"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 relative transition-all bottom-0 group-hover:bottom-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>

                      <span
                        class="w-full justify-center absolute top-5 font-normal hidden group-hover:flex"
                        >Edit</span
                      >
                    </a>
                    <a
                      v-if="isAdmin"
                      class="group hover:text-red-500 text-xs relative"
                      @click="() => showModalDeleteFile(report.id)"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 relative transition-all bottom-0 group-hover:bottom-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>

                      <span
                        class="w-full justify-center absolute top-5 font-normal hidden group-hover:flex"
                        >Delete</span
                      >
                    </a>
                    <ModalFileDelete
                      :file="report"
                      :is-visible="isModalVisibleDeleteFile[report.id]"
                      @update:is-visible="
                        (e) => updateIsModalVisibleDeleteFile(e, report.id)
                      "
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { TabsList, TabsRoot, TabsTrigger } from 'radix-vue'
import ModalFileUpload from '../../components/modal/ModalFileUpload.vue'
import ModalFileDelete from '../../components/modal/ModalFileDelete.vue'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
// import SelectAndSearch from '../../components/SelectAndSearch.vue'
import { MONTHS } from '../../constants.js'

export default {
  components: {
    PageHeader,
    PageContent,
    // SelectAndSearch,
    ModalFileUpload,
    ModalFileDelete,
    TabsList,
    TabsRoot,
    TabsTrigger,
  },

  setup() {
    const store = useStore()
    const isAdmin = store.getters['auth/isAdmin']

    // REPORTS
    const reportsByYears = computed(
      () => store.getters['portal/getReportsByYears'],
    )
    const reportsByYear = computed(
      () => reportsByYears.value[selectedYear.value],
    )

    const reportsByMonths = computed(() => {
      const map = reportsByYear.value.reduce(
        (acc, report) => {
          const year = new Date(report.created_at).getMonth()
          if (!acc[year]) {
            acc[year] = []
          }
          acc[year].push(report)
          return acc
        },
        { ALL: reportsByYear.value },
      )

      return map
    })

    const reportsByMonth = computed(() => {
      return reportsByMonths.value[selectedMonth.value]
    })

    // FILTER: YEAR
    const selectedYear = ref('ALL')
    const selectedYearOption = ref(null)
    const defaultYear = computed(() => store.getters['portal/getReportsDefaultYear'])

    const getYearOptionValue = (key) => {
      const values = reportsByYears.value[key]
      key = key === 'ALL' ? 'All years' : key
      return `${key} (${values?.length})`
    }

    const updateSelectedYear = (e) => {
      const value = e.target.value
      selectedYear.value = Object.keys(reportsByYears.value).find((key) => {
        return getYearOptionValue(key) === value
      })

      updateSelectedMonth()
    }

    // FILTER: MONTH
    const selectedMonth = ref('ALL')

    const selectedYearMonths = computed(() => {
      const ordered = Object.keys(reportsByMonths.value)?.sort((a, b) => {
        const aParsed = parseInt(a, 10)
        const bParsed = parseInt(b, 10)

        const isAInteger = !isNaN(aParsed)
        const isBInteger = !isNaN(bParsed)

        if (isAInteger && isBInteger) {
          return bParsed - aParsed
        }

        if (isAInteger) {
          return -1
        }

        if (isBInteger) {
          return 1
        }

        return b.localeCompare(a)
      })

      return ordered
    })

    const getMonthOptionValue = (key) => {
      const values = reportsByMonths.value[key]
      key = key === 'ALL' ? 'All' : MONTHS[key]
      return `${key} (${values?.length})`
    }

    const updateSelectedMonth = (month) => {
      const defaultMonth = selectedYearMonths.value[0]
      month = month || defaultMonth
      selectedMonth.value = month
    }

    // MODAL: ADD/UPDATE FILE
    const activeUpdateFile = ref(null)
    const isModalVisibleUpdateFile = ref(false)

    const updateIsModalVisibleUpdateFile = (e) => {
      isModalVisibleUpdateFile.value = e
    }

    const showModalUpdateFile = (file) => {
      if (file) {
        activeUpdateFile.value = file
      }

      isModalVisibleUpdateFile.value = true
    }

    const resetModalUpdateFile = () => {
      activeUpdateFile.value = null
    }

    // MODAL: DELETE FILE
    const isModalVisibleDeleteFile = ref(
      reportsByMonth.value?.reduce((obj, item) => {
        obj[item.id] = false
        return obj
      }, {}),
    )

    const updateIsModalVisibleDeleteFile = (e, reportId) => {
      isModalVisibleDeleteFile.value[reportId] = e
    }

    const showModalDeleteFile = (id) => {
      isModalVisibleDeleteFile.value[id] = true
    }

    const init = () => {
      // Set the default selected year/month when we have all the year options
      selectedYear.value = defaultYear.value
      selectedYearOption.value = getYearOptionValue(selectedYear.value)
      updateSelectedMonth()
    }

    // WATCHERS
    watch(reportsByYears, () => init())
    
    onMounted(() => init())

    return {
      // USER
      isAdmin,

      // FILTER: YEAR
      selectedYear,
      selectedYearOption,
      getYearOptionValue,
      updateSelectedYear,

      // FILTER: MONTH
      selectedMonth,
      selectedYearMonths,
      getMonthOptionValue,
      updateSelectedMonth,

      // REPORTS
      reportsByYears,
      reportsByMonth,

      // MODALS
      activeUpdateFile,
      isModalVisibleUpdateFile,
      updateIsModalVisibleUpdateFile,
      showModalUpdateFile,
      resetModalUpdateFile,

      isModalVisibleDeleteFile,
      updateIsModalVisibleDeleteFile,
      showModalDeleteFile,
    }
  },
}
</script>
