<template>
  <main
    class="portalSplitBg w-full p-6 py-16 border-t border-white lg:pb-6 lg:px-12 overflow-y-scroll"
  >
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-16">
      <div
        class="flex max-w-xl flex-col justify-between bg-ces-blue shadow-lg p-10 rounded-2xl text-white"
      >
        <a class="-mt-6 -ml-4" href="https://www.cessl.org.nz/">
          <svg-logo class="h-full" size="200"></svg-logo
        ></a>
        <h5 class="text-ces-teal text-xl mb-2">Welcome to the CES School Portal.</h5>
        <p class="text-sm mb-0">
          In your School Portal, you will find the latest Board documents and
          resources to assist the Board, as well as more information about CES
          and the services we offer.
        </p>
      </div>
      <div
        class="flex max-w-xl flex-col items-start bg-white shadow-lg p-10 pr-4 rounded-2xl"
      >
        <h3 class="text-ces-yellow text-6xl">{{ new Date().getFullYear() }}</h3>
        <h2 class="text-4xl">Board Member Reports</h2>
        <router-link
          :to="{ name: 'boardReports', params: { id: route.params.id } }"
          class="h-full flex hover:text-ces-teal text-base font-normal justify-center underline underline-offset-4 mt-6"
          >View more ></router-link
        >
      </div>
    </div>
    <div class="flex flex-col sm:flex-row justify-between sm:items-center mb-4">
      <h2 class="text-3xl mb-0">News and Information</h2>
      <router-link
        :to="{ name: 'boardResources', params: { id: route.params.id } }"
        class="h-full flex hover:text-ces-teal text-base font-normal underline underline-offset-4"
        >View more ></router-link
      >
    </div>

    <div
      class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4 sm:mx-0 lg:max-w-none lg:grid-cols-3"
    >
      <div
        v-for="card in cards"
        :key="card.id"
        class="flex relative flex-col justify-between bg-white shadow-md pl-6 pt-6 pr-3 pb-6 rounded-2xl group hover:shadow-xl"
      >
        <div class="w-full flex flex-col justify-stretch items-start">
          <h2 class="text-xl font-bold text-ces-teal">{{ card.title }}</h2>
          <span v-if="card.date" class="text-xs text-truegray-400 mb-2">
            {{ card.date }}
          </span>
          <a :href="card.href">
            <span class="absolute inset-0" />
            <p class="text-sm font-normal group-hover:text-ces-blue">
              {{ card.description }}
            </p>
          </a>
        </div>
        <span
          class="text-xs font-Inter leading-6 justify-self-end underline underline-offset-4 group-hover:text-ces-yellow"
        >
          {{ card.CTAText }}
        </span>
      </div>
    </div>
  </main>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  setup() {
    const route = useRoute()

    const cards = [
      {
        id: 1,
        title: 'Admin Conferences',
        href: 'https://www.cessl.org.nz/conferences/',
        description:
          'The School Admin Conferences are a great way to upskill your school Admin Office. Conferences in Auckland, Wellington and Christchurch are available.',
        date: '2024',
        CTAText: 'Learn more >',
      },
      {
        id: 2,
        title: 'Guidance',
        href: 'https://www.cessl.org.nz/tag/tips-and-tricks/',
        description:
          'CES has prepared an article on how to run a board meeting sharing tips and tricks on assist new board members.',
        date: '',
        CTAText: 'Learn more >',
      },
      {
        id: 3,
        title: 'Services',
        href: 'https://www.cessl.org.nz/services/asset-management/',
        description:
          'Schools are required to undertake an asset stocktake to determine the accuracy of schools assets as well maintain an accurate registrar for your annual accounts.',
        date: '',
        CTAText: 'Learn more >',
      },
    ]
    return { route, cards }
  },
}
</script>
