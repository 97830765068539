import { createWebHistory, createRouter, START_LOCATION } from 'vue-router'
import store from '../store/index.js'
import Welcome from './routes/Welcome.vue'
import SignIn from './routes/SignIn.vue'
import ResetPasswordValidation from './routes/ResetPasswordValidation.vue'
import Portal from './routes/Portal.vue'
import PortalBoardReports from './routes/PortalBoardReports.vue'
import PortalDashboard from './routes/PortalDashboard.vue'
import PortalBoardResources from './routes/PortalBoardResources.vue'
// import PortalMeetingDates from './routes/PortalMeetingDates.vue'
import PortalMyBoard from './routes/PortalMyBoard.vue'
import PortalAddBoardMember from './routes/PortalAddBoardMember.vue'

const getSchool = (to) => {
  const schoolId = Number.parseInt(to.params.id) || null
  const userSchools = store.getters['portal/getSchools']
  const defaultSchool = userSchools?.[0]

  const school = schoolId
    ? userSchools.find((school) => school.id === schoolId) || defaultSchool
    : defaultSchool
  return school
}

const mapRouteDisplayOrder = (item, index) => {
  if (item.meta?.navConfig) {
    item = {
      ...item,
      meta: {
        ...item.meta,
        navConfig: {
          ...item.meta.navConfig,
          displayOrder: index,
        },
      },
    }
  }

  return item
}

const routesPortalChildren = [
  {
    path: '',
    name: 'dashboard',
    component: PortalDashboard,
    props: true,
    meta: {
      navConfig: {
        name: 'Overview',
        icon: 'dashboard',
      },
      actions: [],
    },
  },
  {
    path: 'board-reports',
    name: 'boardReports',
    component: PortalBoardReports,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Documents',
        icon: 'stat',
      },
      actions: ['portal/dispatchGetReports'],
    },
  },
  // {
  //   path: 'meeting-dates',
  //   name: 'meetingDates',
  //   component: PortalMeetingDates,
  //   props: true,
  //   meta: {
  //     navConfig: {
  //       name: 'Meeting Dates',
  //       icon: 'calendar',
  //     },
  //     actions: ['portal/dispatchGetMeetings']
  //   }
  // },
  {
    path: 'board-resources',
    name: 'boardResources',
    component: PortalBoardResources,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Resources',
        icon: 'board',
      },
      actions: [],
    },
  },
  {
    path: 'my-board',
    name: 'myBoard',
    component: PortalMyBoard,
    props: true,
    meta: {
      services: true,
      actions: ['portal/dispatchGetOwners', 'portal/dispatchGetMembers'],
    },
  },
  {
    path: 'add-board-member',
    name: 'addBoardMember',
    component: PortalAddBoardMember,
    props: true,
    meta: {
      services: true,
      actions: ['portal/dispatchGetMembers'],
    },
  },
].map(mapRouteDisplayOrder)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/signin',
    name: 'signIn',
    component: SignIn,
    props: (route) => ({ emailQuery: route.query.email }),
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordValidation,
  },
  {
    path: '/portal/:id?',
    name: 'portal',
    component: Portal,
    props: true,
    children: routesPortalChildren,
    meta: { middlewareAuth: true },
    beforeEnter: (to, from, next) => {
      const schoolIdCurrent = Number.parseInt(to.params?.id)
      const schooldIdDefault = getSchool(to)?.id
      // invalid if 'id' is not a number or not one of this user's schools
      const invalidId =
        !Number.parseInt(schoolIdCurrent) ||
        schoolIdCurrent !== schooldIdDefault

      if (invalidId) {
        // if invalid, route to default school
        const id = schooldIdDefault
        next({ name: to.name, params: { id } })
      } else {
        next()
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  window.Path = from.path

  /** Do not allow access to these routes if the user is not an administrator...  */
  if (to.matched.some((record) => record.meta.middlewareAuth)) {
    if (
      from.path !== '/signin' &&
      (
        from === START_LOCATION ||
        (!from.path.includes('/portal') && to.path.includes('/portal')) ||
        (from.path.includes('/admin') && to.path.includes('/portal'))
      )
    ) {
      await auth.updateUser()
    }
    const isAuthenticated = auth.isAuthenticated()
    // Send to homepage if unauthenticated
    if (!isAuthenticated) {
      // not auth'd - redirect to sign in
      next({ path: '/signin' })
      return
    } else {
      // route has changed - make sure the current school is set correctly
      const school = getSchool(to)
      await store.dispatch('portal/dispatchSetSchool', school)

      // dispatch any actions this route needs before mounting
      if (to.meta?.actions) {
        await store.dispatch('global/dispatchUpdateIsLoading', true)
        for (const action of to.meta.actions) {
          await store.dispatch(action)
        }
        await store.dispatch('global/dispatchUpdateIsLoading')
      }
    }
  }

  next()
})

export default router
