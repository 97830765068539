// API AUTHENTICATION AND CLASS HELPER
import store from './store/index.js'

class Auth {
  constructor() {
    const token = window.localStorage.getItem('token')

    if (!token) {
      this.clearUserSession()
    } else {
      this.token = token
      this.gate = this.getItem('gate')
      axios.defaults.headers.common.Authorization = `Bearer ${this.token}`
    }
  }

  /**
   * Parses JSON stringified storage items to actual JS types (boolean, object/null, etc.).
   * Note: this is not used for token handling, which is always just a string.
   *
   * @param {*} item stringified value
   * @returns parsed JS value (boolean, null, object, string)
   */
  getItem(item) {
    return JSON.parse(window.localStorage.getItem(item))
  }

  /**
   * Verify logged-in user credentials and set in local storage.
   *
   * @param {object} user
   */
  setUser(user) {
    this.user = {
      id: user.id,
      name: user.name,
      email: user.email,
      admin_user: user.admin_user || false,
      super_admin_user: user.super_admin_user || false,
      tenant_uuid: user.tenant_uuid,
      schools: user.schools || [],
    }

    this.gate = this.user.admin_user

    window.localStorage.setItem('user', JSON.stringify(this.user))
    window.localStorage.setItem('gate', JSON.stringify(this.gate))
    store.dispatch('auth/dispatchUpdateUser', this.user)
    store.dispatch('portal/dispatchSetSchools', this.user.schools)
  }

  /**
   * Locally store logged-in user credentials (token and expiry);
   * attach token to axios http headers
   *
   * @param {object} token
   * @param {object} user
   * @param {*} expires
   */
  signIn(token, user, expires) {
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('expires', JSON.stringify(expires))
    axios.defaults.headers.common.Authorization = `Bearer ${token}`

    this.token = token
    this.expires = user
    this.setUser(user)
  }

  /**
   * Sign out logged-in user (by clearing session).
   */
  async signOut() {
    this.clearUserSession()
  }

  /**
   * Clear all session user auth credentials.
   * Revoke token and user data (reset to falsy values).
   * Note: token must first be revoked on server side!
   */
  clearUserSession() {
    this.token = ''
    this.user = null
    this.expires = null
    this.gate = false

    window.localStorage.setItem('token', this.token)
    window.localStorage.setItem('user', JSON.stringify(this.user))
    window.localStorage.setItem('expires', JSON.stringify(this.expires))
    window.localStorage.setItem('gate', JSON.stringify(this.gate))
    axios.defaults.headers.common.Authorization = false

    store.dispatch('auth/dispatchUpdateUser')
  }

  /**
   * Refresh logged-in user credentials and update storage,
   * e.g., on refresh, if permissions have changed.
   */
  async updateUser() {
    const user = await store.dispatch('auth/dispatchGetUser')

    if (user) {
      window.localStorage.setItem('expires', JSON.stringify(this.expires))
      this.setUser(user)
    } else {
      this.clearUserSession()
    }
  }

  portWebSession(token, user, expires) {
    this.signIn(token, user, expires)
    return true
  }

  /**
   * Get logged-in user details.
   *
   * @returns {object} logged-in user
   */
  getUser() {
    return this.user
  }

  /**
   * Admin validation. Is (logged-in) user an admin?
   *
   * @returns {boolean} is user an admin
   */
  isAdmin() {
    return !!this.gate
  }

  /**
   * Authentication validation. Is user logged in?
   *
   * @returns {boolean} is user logged in
   */
  isAuthenticated() {
    return !!this.token
  }
}

export default Auth
