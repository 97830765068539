<template>
  <main class="bg-lightgrey">
    <PageHeader title="Board Resources" />

    <PageContent>
      <template #default>
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <a
              v-for="tab in tabs"
              :key="tab.name"
              :href="tab.href"
              :class="[
                tab.current
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
              >{{ tab.name }}</a
            >
          </nav>
        </div>
        <div
          class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 sm:mx-0 lg:max-w-none xl:grid-cols-3"
        >
          <Card v-for="(card, index) in cards" :key="index" :data="card" />
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import Card from '../../components/Card.vue'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'

export default {
  components: { Card, PageHeader, PageContent },

  setup() {
    const cards = [
      {
        title: 'Board Culture',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/Dne48vc',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What is Strategic Planning in Education?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/GfXjgK4',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'Te Whakangārahu Ngātahi | Planning Together',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/xE86JFY',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What Skills Do Board Members Need?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/ENIbHFC',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'Why Join a School Board?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/q124e9L',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What Are Board Minutes?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/zuJipOM',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
    ]
    return { cards }
  },
}
</script>
