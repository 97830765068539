export function getUser(state) {
  return state.user
}

export function isAdmin(_state, getters) {
  return !!getters.getUser?.admin_user
}

export function isSuperAdmin(_state, getters) {
  return !!getters.getUser?.super_admin_user
}
