import { API_BASE, API_BASE_PORTAL, get, post, remove } from './api.js'

export async function signIn(payload) {
  return await post(`${API_BASE}${API_BASE_PORTAL}/signin`, { ...payload })
}

export async function signOut() {
  return await post(`${API_BASE}${API_BASE_PORTAL}/signout`)
}

// export async function getMeetings(clientId) {
//   return await get(`${API_BASE}${API_BASE_PORTAL}/${clientId}/get-meetings`)
// }

// export async function setMeeting(clientId, payload) {
//   return await post(`${API_BASE}${API_BASE_PORTAL}/${clientId}/upsert-meeting`, payload)
// }

// export async function deleteMeeting(clientId, id) {
//   return await remove(`${API_BASE}${API_BASE_PORTAL}/${clientId}/delete-meeting/${id}`)
// }

export async function getReports(clientId) {
  return await post(`${API_BASE}${API_BASE_PORTAL}/${clientId}/get-portalfiles`)
}

export async function getOwners(clientId) {
  return await get(`${API_BASE}${API_BASE_PORTAL}/${clientId}/get-owners`)
}

export async function getMembers(clientId) {
  return await get(`${API_BASE}${API_BASE_PORTAL}/${clientId}/get-users`)
}

export async function setMember(clientId, payload) {
  return await post(
    `${API_BASE}${API_BASE_PORTAL}/${clientId}/upsert-user`,
    payload,
  )
}

export async function deleteMember(clientId, id) {
  return await remove(
    `${API_BASE}${API_BASE_PORTAL}/${clientId}/delete-user/${id}`,
  )
}

export async function addFile(clientId, payload) {
  return await post(
    `${API_BASE}${API_BASE_PORTAL}/${clientId}/add-file`,
    payload,
  )
}

export async function editFile(clientId, payload) {
  return await post(
    `${API_BASE}${API_BASE_PORTAL}/${clientId}/edit-file`,
    payload,
  )
}

export async function deleteFile(clientId, payload) {
  return await post(
    `${API_BASE}${API_BASE_PORTAL}/${clientId}/delete-file`,
    payload,
  )
}
