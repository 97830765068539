import {
  signIn,
  signOut,
  // getMeetings,
  // setMeeting,
  // deleteMeeting,
  getReports,
  getOwners,
  getMembers,
  setMember,
  deleteMember,
  addFile,
  editFile,
  deleteFile,
} from '../../api/portal.js'
import { triggerAlert } from '../util.js'

const handleError = (data = null) => {
  if (data) {
    data.context?.commit(data.mutation)
  }

  // @todo trigger error messaging
  return false
}

export async function dispatchSignIn(context, payload = null) {
  try {
    if (payload) {
      const response = await signIn(payload)
      const data = response.data
      if (data?.success) {
        auth.signIn(data.access_token, data.user, data.expires)
      } else {
        handleError({
          context,
          mutation: 'CLEAR_SCHOOLS',
        })
      }

      return data
    }

    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  }
}

export async function dispatchSignOut() {
  try {
    const response = await signOut()
    const data = response.data
    auth.signOut()

    if (data?.success) {
      return true
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export function dispatchSetSchools(context, schools) {
  try {
    if (schools) {
      context.commit('UPDATE_SCHOOLS', schools)
    } else {
      context.commit('CLEAR_SCHOOLS')
    }
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  }
}

export function dispatchSetSchool(context, school) {
  try {
    if (school) {
      context.commit('UPDATE_SCHOOL', school)
    } else {
      context.commit('CLEAR_SCHOOL')
    }
  } catch {
    return handleError()
  }
}

// export async function dispatchGetMeetings(context) {
//   try {
//     const response = await getMeetings()
//     const data = response.data?.data

//     if (response.data?.success) {
//       context.commit('UPDATE_MEETINGS', data)
//       return true
//     }

//     return handleError({
//       context,
//       mutation: 'CLEAR_MEETINGS',
//     })
//   } catch {
//     return handleError({
//       context,
//       mutation: 'CLEAR_MEETINGS',
//     })
//   }
// }

// export async function dispatchSetMeeting(context, payload = null) {
//   try {
//     const response = await setMeeting(payload)

//     if (response.data?.success) {
//       await context.dispatch('dispatchGetMeetings')
//       return true
//     }

//     return handleError()
//   } catch {
//     return handleError()
//   }
// }

// export async function dispatchDeleteMeeting(context, id = null) {
//   try {
//     if (id) {
//       const response = await deleteMeeting(id)
//       const data = response.data?.data

//       if (data?.success) {
//         await context.dispatch('dispatchGetMeetings')
//         return true
//       }
//     }

//     return handleError()
//   } catch {
//     return handleError()
//   }
// }

export async function dispatchGetReports(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getReports(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_REPORTS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_REPORTS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_REPORTS',
    })
  }
}

export async function dispatchGetOwners(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getOwners(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_OWNERS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_OWNERS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_OWNERS',
    })
  }
}

export async function dispatchGetMembers(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMembers(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_MEMBERS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_MEMBERS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_MEMBERS',
    })
  }
}

export async function dispatchSetMember(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      payload.client_id = clientId
      const response = await setMember(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchDeleteMember(context, id) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await deleteMember(clientId, id)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetMembers')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchAddFile(context, payload) {
  try {
    const clientId = context.getters.getClientId
    payload.append('client_id', clientId)

    if (clientId) {
      const response = await addFile(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetReports')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchEditFile(context, payload) {
  try {
    const clientId = context.getters.getClientId
    payload.append('client_id', clientId)

    if (clientId) {
      const response = await editFile(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetReports')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchDeleteFile(context, payload) {
  try {
    const clientId = context.getters.getClientId
    payload.client_id = clientId

    if (clientId) {
      const response = await deleteFile(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetReports')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}
