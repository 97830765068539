import * as loadingAnimation from './components/animations/loading.json'
import * as loadingAnimationDark from './components/animations/loading-dark.json'

export const ANIMATION_LOADING = {
  DEFAULT: loadingAnimation.default,
  DARK: loadingAnimationDark.default,
}

export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.'

export const LINKS = {
  DJ: 'https://digitaljourney.org/',
  HAIL: 'https://get.hail.to/',
  HELP: 'help@digitaljourney.org'
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
