export default function () {
  return {
    // all the schools this user has access to
    schools: [],
    // current school user is accessing
    school: null,
    // {
    //   uuid : 1,
    //   name : 'School 1'
    // },
    meetings: [],
    reports: [],
    owners: [],
    members: [],
  }
}
