<template>
  <Modal :trigger="MODAL_CONFIG.trigger" :title="MODAL_CONFIG.title" :description="MODAL_CONFIG.description" :content-classes="MODAL_CONFIG.contentClasses">
    <template #trigger>
      <DialogTrigger>
        <li>
          <div
            class="group flex cursor-pointer items-center gap-x-4 rounded-md px-6 py-4 text-lg leading-6 font-semibold hover:text-ces-teal"
          >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 hover:text-ces-teal">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
          </svg>
            Walkthrough
          </div>
        </li>
      </DialogTrigger>
    </template>
    <template #content>
      <video width="1000" class="w-full" controls>
        <source src="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/CES+Portal+Walkthrough+Video.mp4" type="video/mp4">
        Your browser does not support HTML video.
      </video>
    </template>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import { DialogTrigger } from 'radix-vue'
import Modal from './Modal.vue'

export default defineComponent({
  name: 'ModalVideo',

  components: {
    Modal,
    DialogTrigger,
  },

  setup() {
    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: 'hidden',
        ref: 'modalVideoTriggerRef'
      },

      title: {
        text: 'Walkthrough',
        classes: '',
      },

      description: {
        text: 'How to use the CES Board Portal',
        classes: '',
      },

      contentClasses: 'w-[90vw]'
    }

    return {
      MODAL_CONFIG,
    }
  },
})
</script>
