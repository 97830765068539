<template>
  <DialogRoot>
    <slot name="trigger">
      <DialogTrigger
        v-if="trigger"
        :as="trigger.as || 'button'"
        :class="trigger.classes"
      >
        {{ trigger.text }}
      </DialogTrigger>
    </slot>
    <DialogPortal>
      <DialogOverlay class="bg-gray-600 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-80">
        <DialogContent
          class="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-[100] rounded-xl"
          :class="contentClasses"
        >
          <DialogTitle v-if="title" as="h3" :class="['text-h3 mb-4', title.classes]">
            {{ title.text }}
          </DialogTitle>
          <DialogDescription v-if="description" as="h5" :class="['text-h5 mb-3', description.classes]">
            {{ description.text }}
          </DialogDescription>
          
          <slot name="content" />
          
          <div v-if="submitButton" class="flex justify-end mt-4">
            <DialogClose as-child>
              <button
                type="button"
                :class="submitButton.classes"
                @click="submitButton.action"
              >
                {{ submitButton.text }}
              </button>
            </DialogClose>
          </div>
          <DialogClose
            class="absolute top-[10px] right-[10px] outline-none"
            aria-label="Close"
          >
            <svg-icon
              aria-hidden="true"
              size="20"
              icon-name="close"
              class="text-gray-500 hover:text-truegray-900"
            />
          </DialogClose>
        </DialogContent>
      </DialogOverlay>
    </DialogPortal>
  </DialogRoot>
</template>

<script>
import { defineComponent } from 'vue'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'

export default defineComponent({
  name: 'Modal',

  components: {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogOverlay,
    DialogPortal,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
  },

  props: {
    trigger: {
      type: Object,
      default: null
    },

    title: {
      type: Object,
      default: null
    },

    description: {
      type: Object,
      default: null
    },

    contentClasses: {
      type: String,
      default: ''
    },

    submitButton: {
      type: Object,
      default: null
    },
  },
})
</script>
