<template>
  <main class="bg-lightgrey">
    <PageHeader :title="pageTitle">
      <template #action>
        <router-link
          :to="{ name: 'myBoard', params: { id: route.params.id } }"
          class="flex justify-center items-center rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border border-ces-blue sm:w-auto sm:text-sm hover:text-white hover:bg-ces-blue"
        >
          <svg-icon
            aria-hidden="true"
            size="16"
            icon-name="arrow"
            class="rotate-90"
          />Back to My Board
        </router-link>
      </template>
    </PageHeader>

    <PageContent>
      <template #default>
        <div class="space-y-10 divide-y divide-gray-200">
          <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <div class="px-4 sm:px-0">
              <h2 class="text-base font-Inter font-bold">Profile</h2>
              <p class="mt-1 text-xs text-gray-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>

            <div
              v-if="hasSubmitted"
              class="w-full flex flex-col space-y-6 px-4 py-6 col-span-2"
            >
              <div>
                {{ name }} has been
                {{ member ? 'updated' : 'added to your board' }}!
              </div>
              <div class="flex items-center justify-end gap-x-6 w-full px-4">
                <button
                  type="button"
                  class="rounded-full gap-x-2 px-6 py-2 text-base font-Inter font-bold sm:text-sm leading-6 text-gray-900 hover:bg-ces-blue hover:text-white border border-ces-blue"
                  @click="routeToMyBoard"
                >
                  Go to My Board
                </button>
                <button
                  type="button"
                  class="rounded-full shadow-sm gap-x-2 px-6 py-2 text-base font-Inter font-bold sm:text-sm bg-ces-teal hover:bg-ces-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ces-blue border border-transparent"
                  @click="callFreshForm"
                >
                  Add another member
                </button>
              </div>
            </div>
            <form
              v-else
              ref="form"
              class="bg-white shadow-sm sm:rounded-xl md:col-span-2"
              @submit.prevent="submit"
            >
              <div class="px-4 py-6 sm:p-8">
                <div
                  class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="first-name"
                      class="block text-xs font-medium leading-6 text-gray-900"
                      >Full name (required)</label
                    >
                    <div class="mt-2">
                      <input
                        id="first-name"
                        v-model="name"
                        required
                        type="text"
                        name="first-name"
                        autocomplete="given-name"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-4">
                    <label
                      for="boardRole"
                      class="block text-xs font-medium leading-6 text-gray-900"
                      >Role (required)</label
                    >
                    <div class="mt-2">
                      <select
                        id="boardRole"
                        v-model="boardRole"
                        required
                        name="boardRole"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                      >
                        <option disabled value="">Please select a role</option>
                        <option value="Presiding Member">Presiding Member</option>
                        <option value="Board Member">Board Member</option>
                        <option value="Board Secretary">Board Secretary</option>
                        <option value="Principal">Principal</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-4">
                    <label
                      for="email"
                      class="block text-xs font-medium leading-6 text-gray-900"
                      >Email (required)</label
                    >
                    <div class="mt-2">
                      <input
                        id="email"
                        v-model="email"
                        required
                        type="email"
                        name="email"
                        autocomplete="given-name"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="phone"
                      class="block text-xs font-medium leading-6 text-gray-900"
                      >Mobile number</label
                    >
                    <div class="mt-2">
                      <input
                        id="phone"
                        v-model="mobile"
                        type="text"
                        name="phone"
                        autocomplete="phone"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="phone"
                      class="block text-xs font-medium leading-6 text-gray-900"
                      >Phone number</label
                    >
                    <div class="mt-2">
                      <input
                        id="phone"
                        v-model="phone"
                        type="text"
                        name="phone"
                        autocomplete="phone"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center justify-end gap-x-4 border-t border-gray-900/10 px-4 py-4 sm:px-8"
              >
                <button
                  type="button"
                  class="rounded-full gap-x-2 px-6 py-2 text-base font-Inter font-bold sm:text-sm leading-6 text-gray-900 hover:bg-ces-blue hover:text-white border"
                  :class="
                    member ? 'border-ces-blue shadow-sm' : 'border-transparent'
                  "
                  @click="reset"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  :disabled="!canSubmit"
                  :class="[
                    'rounded-full shadow-sm gap-x-2 px-6 py-2 text-base font-Inter font-bold sm:text-sm border border-transparent',
                    canSubmit
                      ? 'bg-ces-teal hover:bg-ces-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ces-blue'
                      : 'cursor-not-allowed bg-gray-100 hover:bg-gray-100 text-gray-300',
                  ]"
                >
                  <div v-if="isSubmitting" class="h-6 w-6">
                    <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
                  </div>
                  <span v-else>
                    {{ member ? 'Update' : 'Save' }}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'

export default {
  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const members = computed(() => store.getters['portal/getMembersMapped'])

    const form = ref(null)
    const isSubmitting = ref(false)
    const hasSubmitted = ref(false)

    const member = ref(null)
    const name = ref('')
    const boardRole = ref('')
    const email = ref('')
    const mobile = ref('')
    const phone = ref('')

    const pageTitle = computed(() => {
      return `${member.value ? 'Update' : 'New'} Board Member`
    })

    const payload = computed(() => {
      return {
        ...(member.value ? { id: member.value.id } : {}),
        name: name.value,
        email: email.value,
        board_role: boardRole.value,
        tel: phone.value,
        mobile: mobile.value,
      }
    })

    const canSubmit = computed(() => {
      let verified =
        !hasSubmitted.value &&
        !isSubmitting.value &&
        name.value &&
        boardRole.value &&
        email.value 
      if (member.value) {
        // updating existing member: confirm at least one field has been updated
        const hasBeenUpdated =
          member.value.name !== name.value ||
          member.value.boardRole !== boardRole.value ||
          member.value.email !== email.value ||
          member.value.mobile !== mobile.value ||
          member.value.phone !== phone.value
        verified = verified && hasBeenUpdated
      }

      // creating a new member
      return verified
    })

    const routeToMyBoard = () => {
      router.push({ name: 'myBoard', params: { id: route.params.id } })
    }

    const callFreshForm = () => {
        init(true)
    }

    const reset = () => {
      init()
    }

    const submit = async () => {
      isSubmitting.value = true
      const response = await store.dispatch(
        'portal/dispatchSetMember',
        payload.value,
      )
      if (response) {
        hasSubmitted.value = true
      }
      isSubmitting.value = false
    }

    const init = (callFresh = false) => {
      if (member.value && !callFresh) {
        name.value = member.value.name
        boardRole.value = member.value.boardRole
        email.value = member.value.email
        mobile.value = member.value.mobile
        phone.value = member.value.phone
      } else {
        name.value = ''
        boardRole.value = ''
        email.value = ''
        mobile.value = ''
        phone.value = ''
      }

      hasSubmitted.value = false
    }

    onMounted(() => {
      // Go and fetch the (existing) member
      const memberId = parseInt(route.query?.member)
      if (memberId) {
        // we are editing an existing user!
        member.value = store.getters['portal/getMember'](memberId)
        if (!member.value) {
          // if we can't find that member, refresh/reset the form
          callFreshForm(true)
        }
      } else {
        // we are creating a new user!
        member.value = null
      }

      init()
    })

    return {
      ANIMATION_LOADING,
      route,
      form,
      pageTitle,
      member,
      name,
      boardRole,
      email,
      phone,
      mobile,
      canSubmit,
      isSubmitting,
      hasSubmitted,
      routeToMyBoard,
      callFreshForm,
      reset,
      submit,
    }
  },
}
</script>
