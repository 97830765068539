export const triggerAlert = (context, response) => {
  if (response?.data?.alert) {
    context.dispatch('global/dispatchUpdateAlertToast', response.data.alert, { root: true })
  }
}

export const mapUser = (user) => {
  return {
    id: user.id,
    name: user.name,
    boardRole: user.board_role,
    role: user.role,
    isAdmin: user.role < 3,
    email: user.email,
    mobile: user.mobile,
    phone: user.tel,
  }
}

export const mapReport = (report) => {
  return {
    ...report,
    date: new Date(report.created_at).toLocaleDateString(),
  }
}