<template>
  <div class="w-full lg:px-8 text-ces-blue">
    <AlertToast />
    <div
      class="w-full max-w-7xl mx-auto px-6 lg:px-0 flex justify-between lg:h-[10vh] text-white"
    >
      <div class="flex-1 flex items-center justify-end gap-x-8">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cessl.org.nz/contact/"
          class="hover:text-ces-teal"
          ><svg-icon
            icon-name="email"
            aria-hidden="true"
            class="w-8 h-8 sm:w-6 sm:h-6 flex sm:hidden"
          /><span class="hidden sm:flex">Contact</span>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cessl.org.nz/"
          class="sm:rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border-l sm:border border-white sm:text-sm text-white sm:hover:bg-white hover:text-ces-teal"
          ><svg-icon
            icon-name="home"
            aria-hidden="true"
            class="w-8 h-8 sm:w-6 sm:h-6 flex sm:hidden"
          /><span class="hidden sm:flex">Our Website</span>
        </a>
        <a
          v-if="isSuperAdmin"
          class="sm:rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border-l sm:border bg-white border-white sm:text-sm text-ces-teal flex hover:text-ces-teal items-center justify-center space-x-2"
          @click="routeToAdminArea"
        >
          <span class="hidden sm:flex hover:text-ces-teal">Admin Area</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"
            />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
            />
          </svg>
        </a>
      </div>
    </div>
    <!-- application view -->
    <div
      class="bg-white w-full max-w-7xl mx-auto lg:h-[85vh] lg:rounded-3xl overflow-hidden lg:flex"
    >
      <!--
        <MobileNav
        class="flex lg:hidden"
        /> 
      -->
      <SideNav class="hidden lg:flex" />
      <!-- Main content -->
      <router-view class="w-full overflow-y-scroll" />
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AlertToast from '../../components/AlertToast.vue'
import SideNav from '../../components/SideNav.vue'
import PageFooter from '../../components/PageFooter.vue'

export default {
  components: {
    AlertToast,
    SideNav,
    PageFooter,
  },

  setup() {
    const store = useStore()
    const isSuperAdmin = computed(() => store.getters['auth/isSuperAdmin'])

    const routeToAdminArea = () => {
      const currentUrl = window.location.href
      let parts = currentUrl.split('/')
      if (parts.length > 4 && parts[3] === 'portal') {
        parts = parts.slice(0, 5) // Keep only the first 5 parts
        parts.splice(3, 0, 'admin')
      }

      window.location.href = parts.join('/')
    }

    return {
      isSuperAdmin,
      routeToAdminArea,
    }
  },
}
</script>
