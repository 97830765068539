<template>
  <div
    class="flex flex-col sm:flex-row justify-between items-center gap-x-4 bg-white p-6 py-16 lg:pt-24 lg:pb-6 lg:px-12"
  >
    <h2 class="text-3xl">{{ title }}</h2>
    <slot name="action" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageHeader',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
</script>
